import { colDef } from "@src/helpers/utils";
import React, { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IColumn } from "@src/components/entity_table/entity_table";
import { ITableBulkAction } from "@src/components/type/tabel-bulk-action-type";
import { GenericList } from "@src/components/generic-list/generic-list";
import { Filter } from "@src/containers/private/diagram-definition/filter";
import { DiagramDefinitionRepository } from "@src/services/diagram-definition.repository";
import {
  IDiagramDefinition,
  IDiagramDefinitionFilter,
} from "@src/models/diagram-definition.model";
import { HeaderList } from "@src/containers/private/diagram-definition/header";
export interface IProps {
  diagramRepo?: DiagramDefinitionRepository;
}

export const DiagramDefinitionList: React.FC<IProps> = ({ diagramRepo }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [bulkSelected, setBulkSelected] = useState<Array<IDiagramDefinition>>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [repo, setRepo] = useState<DiagramDefinitionRepository>(
    diagramRepo || new DiagramDefinitionRepository()
  );

  const fields: Array<keyof IDiagramDefinitionFilter> = [
    "name",
    "title",
    "since",
    "before",
    "page_size",
  ];
  const query: IDiagramDefinitionFilter = {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields.forEach((x) => ((query as any)[x] = searchParams.get(x)));

  useEffect(() => {
    setRepo(diagramRepo || repo);
  }, [diagramRepo != null]);

  const bulkAction: Array<ITableBulkAction> = [
    {
      icon: <DeleteIcon />,
      title: t("delete"),
      isDisabled: false,
      callBack: () => {
        return;
      },
    },
  ];
  const columns: IColumn<IDiagramDefinition>[] = [
    colDef("name", t("name")),
    colDef("title", t("title")),
    colDef("description", t("description"), (_, y) =>
      y.description ? y.description : ""
    ),
  ];

  return (
    <Fragment>
      <Helmet>
        <title>{t("page__diagram__diagram definitions")}</title>
      </Helmet>
      <ToastContainer />
      <GenericList
        stateError={[error, setError]}
        stateLoading={[loading, setLoading]}
        stateSelectedItems={[bulkSelected, setBulkSelected]}
        bulkActionList={bulkAction}
        bulkSelectedMsgTemplate={
          bulkSelected.length > 1
            ? t("page__diagram__diagrams selected", {
                num: bulkSelected.length,
              })
            : t("page__diagram__diagram selected", {
                num: bulkSelected.length,
              })
        }
        columns={columns}
        HeaderComponent={HeaderList}
        FilterComponent={Filter}
        initQuery={query}
        repo={repo}
        rowActionList={[]}
      />
    </Fragment>
  );
};
