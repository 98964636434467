import {
  IDiagramDefinition,
  IDiagramDefinitionFilter,
} from "@src/models/diagram-definition.model";
import { Repository } from "@src/services/repository";

export class DiagramDefinitionRepository extends Repository<
  IDiagramDefinition,
  IDiagramDefinition,
  IDiagramDefinition,
  IDiagramDefinition,
  IDiagramDefinitionFilter
> {
  constructor() {
    super("/v1.0/diagram_definition");
  }
}
