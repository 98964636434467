import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddCommentIcon from "@mui/icons-material/AddComment";
import { useTranslation } from "react-i18next";
import { Button, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useAppSelector } from "@src/redux/hooks";
import { IReduxState } from "@src/redux/root-reducer";
import { EPRIVATEROUTE } from "@src/constants/enum/private-route.enum";
import SchemaIcon from "@mui/icons-material/Schema";

interface IProps {
  handleRefresh?: () => void;
  loading?: boolean;
}
export const HeaderList: React.FC<IProps> = ({
  handleRefresh,
  loading = false,
}) => {
  const { t } = useTranslation();
  const selectedProject = useAppSelector(
    (state: IReduxState) => state.project.selectedProject
  );
  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={4}
      className="list-header"
      data-testid="diagram-definition-header"
    >
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <SchemaIcon />
        <Typography variant="h4">
          {t("page__diagram__diagram definition")}
        </Typography>
      </Stack>
      <Button
        disabled={loading}
        data-testid="create-diagram-definition"
        variant="text"
        component={Link}
        to={`${EPRIVATEROUTE.DIAGRAM_DEFINITION}?selectedProject=${selectedProject.name}`}
        startIcon={<AddCommentIcon />}
      >
        {t("page__diagram__new diagram")}
      </Button>
      <Button
        disabled={loading}
        data-testid="refreshPage"
        variant="text"
        onClick={handleRefresh}
        startIcon={<RefreshIcon />}
      >
        {t("refresh")}
      </Button>
    </Stack>
  );
};
