import { IColumn } from "@src/components/entity_table/entity_table";
import { ILabel } from "@src/models/label.model";
import { IFilterParams } from "@src/services/repository";
export const getCookieLanguages = () => {
  const value = "; " + document.cookie;
  const parts: Array<string> = value.split("; i18next=");
  if (parts.length === 2) return parts?.pop()?.split(";").shift();
  return "en";
};
export const removeNullValue = <T>(obj: T): T => {
  const newObject: Partial<T> = {};

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value !== null && value !== "") {
        newObject[key] = value;
      }
    }
  }

  return newObject as T;
};

export const delayCallback = (
  callback: () => void,
  delay: number
): NodeJS.Timeout => {
  return setTimeout(callback, delay);
};
export const updateSelectProjectOnParam = (
  projectName: string,
  search: string
) => {
  const urlParams = new URLSearchParams(search);
  urlParams.set("selectedProject", projectName);
  const newSearch = urlParams.toString();
  return newSearch;
};
export function colDef<T>(
  colName: keyof T,
  colCellName: string,
  render?: (col: IColumn<T>, record: T) => React.ReactNode
) {
  return {
    align: "left",
    title: colCellName,
    key: colName,
    render: render,
  } as IColumn<T>;
}

export const hasNonNullableProperties = <T extends IFilterParams>(
  obj: T
): boolean => {
  const modifiedObj = { ...obj };
  delete modifiedObj.page_size;
  delete modifiedObj.before;
  delete modifiedObj.since;

  return Object.values(modifiedObj).some((value) => value !== null);
};

export function updateUrlParamsWithQuery<T extends IFilterParams>(
  query: T,
  urlParams: URLSearchParams,
  url: string
): string {
  //delete query.page_size;
  const keys = Object.keys(query) as Array<keyof T>;
  const validKeys = keys.filter(
    (x: keyof T) => query[x] != null || query[x] != "" || query[x] != undefined
  );
  const nullKeys = keys.filter(
    (x: keyof T) => query[x] == null || query[x] == "" || query[x] == undefined
  );
  validKeys.forEach((key) =>
    urlParams.set(key as string, query[key] as string)
  );
  nullKeys.forEach((key) => urlParams.delete(key as string));
  const newSearch = urlParams.toString();
  const params = newSearch.split("&");
  const sortedParams = params.sort();
  const newUrl = `${url}?${sortedParams.join("&")}`;
  return newUrl;
}

export function convertArrayToObject(
  labelsArray: { key: string; value: string }[]
): ILabel {
  return labelsArray.reduce(
    (acc: ILabel, { key, value }: { key: string; value: string }) => {
      acc[key] = value;
      return acc;
    },
    {}
  );
}

export function convertObjToArray(labelsObject: ILabel) {
  const convertedLabelsArray = Object.entries(labelsObject).map(
    ([key, value]) => ({ key, value })
  );
  return convertedLabelsArray;
}
interface SelectedItem<T> {
  value: T;
  index: number;
}

export const updateSelectedTypeAtIndex = <T>(
  prevSelectedType: SelectedItem<T>[] | null,
  data: T,
  index: number
): SelectedItem<T>[] => {
  if (prevSelectedType === null) {
    return [{ value: data, index }];
  }

  const existingIndex = prevSelectedType.findIndex(
    (item) => item.index === index
  );

  if (existingIndex !== -1) {
    const updatedSelectedType = [...prevSelectedType];
    updatedSelectedType[existingIndex] = { value: data, index };
    return updatedSelectedType;
  } else {
    return [...prevSelectedType, { value: data, index }];
  }
};
export const truncateText = (text: string, maxLength = 16) => {
  return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
};
